// AccountSection.js
import React, { useState, useEffect, useCallback } from 'react';

function AccountSection({ isAuthenticated, onLogout }) {
  const [accountData, setAccountData] = useState([]);
  const [schedulerStatus, setSchedulerStatus] = useState(() => initializeSchedulerStatusAllAccounts());
  const [schedulerStatusOneAccount, setSchedulerStatusOneAccount] = useState(() => initializeSchedulerStatus());
  const [newAccountEmail, setNewAccountEmail] = useState('');
  const [newAccountKey, setNewAccountKey] = useState('');
  const [accountHistories, setAccountHistories] = useState([]);
  const [accountEmail, setAccountEmail] = useState(''); 
  const [lastCheckedTimestamps, setLastCheckedTimestamps] = useState({});
  const [withdrawAmounts, setWithdrawAmounts] = useState({});

  //history section
  const [visibleHistories, setVisibleHistories] = useState({});
  const [loadingHistories, setLoadingHistories] = useState({});

/// Lazy initializer function to load state from local storage if it exists
function initializeSchedulerStatus() {
  const storedSchedulerStatus = localStorage.getItem('schedulerStatusOneAccount');
  return storedSchedulerStatus ? JSON.parse(storedSchedulerStatus) : {};
}

function initializeSchedulerStatusAllAccounts() {
  const storedSchedulerStatus = localStorage.getItem('schedulerStatusAllAccounts');
  return storedSchedulerStatus ? JSON.parse(storedSchedulerStatus) : 'stop';
}

useEffect(() => {
  if (accountData.length > 0) {
    accountData.forEach((account) => {
      const storedStatus = JSON.parse(localStorage.getItem("schedulerStatusOneAccount")) || {};
      if (!storedStatus[account.email]) {
        checkSchedulerStatusForOneAcc(account.email);
      } else {
        console.log("Using stored status for: " + account.email);
      }
    });
  }
}, [accountData]); // Runs only when accountData changes


const checkSchedulerStatus = () => {
  fetch(`http://${process.env.REACT_APP_API_URL}/api/v1/scheduler-status`)
    .then(response => response.json())
    .then(data => {
      setSchedulerStatus((prevStatus) => ({
        ...prevStatus,
        [accountEmail]: data.status === 'running' ? 'start' : 'stop',
      }));
    })
    .catch(error => {
      console.error('Error checking scheduler status:', error);
    });
};

const checkSchedulerStatusForOneAcc = (accountEmail) => {
  fetch(`http://${process.env.REACT_APP_API_URL}/api/v1/scheduler-status-for-one-account?accountEmail=${encodeURIComponent(accountEmail)}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(`Network response was not ok (status ${response.status})`);
      }
      return response.json();
    })
    .then((data) => {
      setSchedulerStatusOneAccount((prevStatus) => ({
        ...prevStatus,
        [accountEmail]: data.status === 'running' ? 'start' : 'stop',
      }));
    })
    .catch((error) => {
      console.error('Error checking scheduler status:', error);
    });
};


useEffect(() => {
  if (Object.keys(schedulerStatusOneAccount).length > 0) {
    localStorage.setItem('schedulerStatusOneAccount', JSON.stringify(schedulerStatusOneAccount));
  }
}, [schedulerStatusOneAccount]);

useEffect(() => {
  if (Object.keys(schedulerStatus).length > 0) {
    localStorage.setItem('schedulerStatusAllAccounts', JSON.stringify(schedulerStatus));
  }
}, [schedulerStatus]);


  useEffect(() => {
    fetchAccountData();
    //fetchAccountHistories();
    const intervalId = setInterval(fetchAccountData, 5 * 60 * 1000); // 5 minutes in milliseconds

    // Clean up the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);

  function AppStatus() {
    const [isAppRunning, setIsAppRunning] = useState(false);

    useEffect(() => {
      const checkAppStatus = async () => {
        console.log('app status');
        try {
          const response = await fetch(`http://${process.env.REACT_APP_API_URL}/api/v1/ping`);
          if (response.ok) {
            setIsAppRunning(true);
          } else {
            setIsAppRunning(false);
          }
        } catch (error) {
          setIsAppRunning(false);
        }
      };

      const statusCheckInterval = setInterval(checkAppStatus, 300000);

      checkAppStatus();

      return () => clearInterval(statusCheckInterval);
    }, []);

    return (
      <div className={`alert ${isAppRunning ? 'alert-success' : 'alert-danger'}`}>
        {isAppRunning ? (
          <p>The application is healthy.</p>
        ) : (
          <p>The application is not healthy.</p>
        )}
      </div>
    );
  }

  const fetchAccountData = () => {
    fetch(`http://${process.env.REACT_APP_API_URL}/api/v1/accounts/accounts`)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        if (data.length > 0) {
          setAccountData(data);

          // // Initialize schedulerStatusOneAccount for each account (default to 'stop')
          // const schedulerStatuses = {};
          // data.forEach((account) => {
          //   schedulerStatuses[account.email] = 'stop'; // Set initial value to 'stop'
          // });
          // setSchedulerStatusOneAccount(schedulerStatuses);

          // Update the lastCheckedTimestamps state for each account
          const updatedTimestamps = {};
          data.forEach((account) => {
            // Use the account email as the key to store the last checked timestamp
            updatedTimestamps[account.email] = new Date().toLocaleString();
          });
          setLastCheckedTimestamps(updatedTimestamps);
        } else {
          console.log('Error');
        }
      })
      .catch((error) => console.log('Error:', error));
  };

  const fetchAccountHistories = async () => {
    fetch(`http://${process.env.REACT_APP_API_URL}/api/v1/get-accounts-histories`)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {

        if (data && data.combinedHistory !== null && Array.isArray(data.combinedHistory)) {
          setAccountHistories(data.combinedHistory);
        } else {
          console.log('combinedHistory is null or not an array');
          // Handle the case where combinedHistory is null or not an array
          // You can set accountHistories to an empty array or display an error message
          setAccountHistories([]);
        }
      })
      .catch((error) => console.log('Error fetching account histories:', error));
  };

  const handleTradeWithdrawawButtonClick = (account) => {
    fetch(`http://${process.env.REACT_APP_API_URL}/api/v1/trigger-scheduler-for-account`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ accountEmail: account.email }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      })
      .then((data) => {
        console.log('Response data:', data);
        if (data.message === 'Success') {
          // Check if the response indicates success
          console.log('Request was successful for account:', account.email);
          const updatedAccountData = accountData.map((acc) => {
            if (acc.email === account.email) {
              return { ...acc, success: true, failed: false };
            }
            return acc;
          });
          setAccountData(updatedAccountData);
        } else {
          // Check if the response indicates failure
          console.log('Request failed for account:', account.email);
          const updatedAccountData = accountData.map((acc) => {
            if (acc.email === account.email) {
              return { ...acc, success: false, failed: true };
            }
            return acc;
          });
          setAccountData(updatedAccountData);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        const updatedAccountData = accountData.map((acc) => {
          if (acc.email === account.email) {
            return { ...acc, success: false, failed: true };
          }
          return acc;
        });
        setAccountData(updatedAccountData);
      });
  };

  const withdrawSpecifiedUSDTButton = (accountEmail, specifiedUSDTAmount) => {
    fetch(`http://${process.env.REACT_APP_API_URL}/api/v1/withdraw-specified-usdt`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ accountEmail, usdtAmount: specifiedUSDTAmount }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      })
      .then((data) => {
        console.log('Response data:', data);
        if (data.message === 'Success') {
          const updatedAccountData = accountData.map((acc) => {
            if (acc.email === accountEmail) {
              return { ...acc, success: true, failed: false };
            }
            return acc;
          });
          setAccountData(updatedAccountData);
        } else {
          const updatedAccountData = accountData.map((acc) => {
            if (acc.email === accountEmail) {
              return { ...acc, success: false, failed: true };
            }
            return acc;
          });
          setAccountData(updatedAccountData);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        const updatedAccountData = accountData.map((acc) => {
          if (acc.email === accountEmail) {
            return { ...acc, success: false, failed: true };
          }
          return acc;
        });
        setAccountData(updatedAccountData);
      });
  };
  
  const withdrawAllUSDTButton = (accountEmail) => {
    fetch(`http://${process.env.REACT_APP_API_URL}/api/v1/withdraw-all-usdt`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ accountEmail: accountEmail }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      })
      .then((data) => {
        console.log('Response data:', data);
        if (data.message === 'Success') {
          // Check if the response indicates success
          console.log('Request was successful for account:', accountEmail);
          const updatedAccountData = accountData.map((acc) => {
            if (acc.email === accountEmail) {
              return { ...acc, success: true, failed: false };
            }
            return acc;
          });
          setAccountData(updatedAccountData);
        } else {
          // Check if the response indicates failure
          console.log('Request failed for account:', accountEmail);
          const updatedAccountData = accountData.map((acc) => {
            if (acc.email === accountEmail) {
              return { ...acc, success: false, failed: true };
            }
            return acc;
          });
          setAccountData(updatedAccountData);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        const updatedAccountData = accountData.map((acc) => {
          if (acc.email === accountEmail) {
            return { ...acc, success: false, failed: true };
          }
          return acc;
        });
        setAccountData(updatedAccountData);
      });
  };

  const convertZar = (accountEmail) => {
    fetch(`http://${process.env.REACT_APP_API_URL}/api/v1/convert-zar`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ accountEmail: accountEmail }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      })
      .then((data) => {
        console.log('Response data:', data);
        if (data.message === 'Success') {
          // Check if the response indicates success
          console.log('Request was successful for account:', accountEmail);
          const updatedAccountData = accountData.map((acc) => {
            if (acc.email === accountEmail) {
              return { ...acc, success: true, failed: false };
            }
            return acc;
          });
          setAccountData(updatedAccountData);
        } else {
          // Check if the response indicates failure
          console.log('Request failed for account:', accountEmail);
          const updatedAccountData = accountData.map((acc) => {
            if (acc.email === accountEmail) {
              return { ...acc, success: false, failed: true };
            }
            return acc;
          });
          setAccountData(updatedAccountData);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        const updatedAccountData = accountData.map((acc) => {
          if (acc.email === accountEmail) {
            return { ...acc, success: false, failed: true };
          }
          return acc;
        });
        setAccountData(updatedAccountData);
      });
  };

  const toggleSchedulerForOneAccount = (accountEmail) => {
    const currentStatus = schedulerStatusOneAccount[accountEmail];
    const newStatus = currentStatus === 'stop' ? 'start' : 'stop';

    fetch(`http://${process.env.REACT_APP_API_URL}/api/v1/${newStatus}-scheduler-for-one-account`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ accountEmail }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Network response was not ok (status ${response.status})`);
        }
        return response.json();
      })
      .then((data) => {
        console.log('Received data:', data);
        // Update the scheduler status for the account
        setSchedulerStatusOneAccount((prevStatus) => ({
          ...prevStatus,
          [accountEmail]: newStatus,
        }));
      })
      .catch((error) => {
        console.error('Error starting/stopping scheduler:', error);
      });
  };

  const toggleScheduler = () => {
    const newStatus = schedulerStatus === 'stop' ? 'start' : 'stop';

    fetch(`http://${process.env.REACT_APP_API_URL}/api/v1/${newStatus}-scheduler`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Network response was not ok (status ${response.status})`);
        }
        return response.json();
      })
      .then((data) => {
        console.log('Received data:', data);
        setSchedulerStatus(newStatus);
      })
      .catch((error) => {
        console.error('Error starting scheduler:', error);
      });
  };

  const handleAddAccount = () => {
    const cleanedEmail = newAccountEmail.trim();

    fetch(`http://${process.env.REACT_APP_API_URL}/api/v1/insert-account`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ accountEmail: cleanedEmail, key: newAccountKey }),
    })
      .then((response) => response.json())
      .then((data) => {
        fetchAccountData();
        setNewAccountEmail('');
        setNewAccountKey('');
      })
      .catch((error) => {
        console.error('Error adding account:', error);
      });
  };

  const handleDeleteAccount = (accountEmail) => {
    fetch(`http://${process.env.REACT_APP_API_URL}/api/v1/delete-account/${accountEmail}`, {
      method: 'DELETE',
    })
      .then((response) => response.json())
      .then((data) => {
        fetchAccountData();
      })
      .catch((error) => {
        console.error('Error deleting account:', error);
      });
  };

  //history section
  

  const toggleWithdrawalHistory = (accountEmail) => {
    const currentVisibility = visibleHistories[accountEmail];
    const action = currentVisibility ? 'hide' : 'show';
  
    fetch(`http://${process.env.REACT_APP_API_URL}/api/v1/get-withdrawals-history-for-account-temp`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ accountEmail }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Network response was not ok (status ${response.status})`);
        }
        return response.json();
      })
      .then((data) => {
        console.log('Received data:', data);
  
        if (data.success) {
          // Toggle the visibility for the account's withdrawal history
          setVisibleHistories((prevVisibility) => ({
            ...prevVisibility,
            [accountEmail]: !currentVisibility,
          }));
  
          // Optionally update the withdrawal history data
          if (!currentVisibility) {
            updateAccountHistory(accountEmail, data.data);
          }
        } else {
          console.error('Failed to fetch withdrawal history:', data.message);
        }
      })
      .catch((error) => {
        console.error('Error toggling withdrawal history:', error);
      });
  };
  
  // Helper function to update the account history
  const updateAccountHistory = (accountEmail, history) => {
    setAccountData((prevData) =>
      prevData.map((account) =>
        account.email === accountEmail
          ? { ...account, withdrawalHistory: history }
          : account
      )
    );
  };
  


  return (
    <div className="container mt-5">
      {/* Page Header */}
      <header className="mb-4">
        <h1>Accounts</h1>
        <AppStatus />
      </header>

      {isAuthenticated && (
        <>
          {/* Scheduler Controls */}
          <div className="d-flex align-items-center gap-3 mb-4">
            <button className="btn btn-info" onClick={toggleScheduler}>
              {schedulerStatus === 'stop' ? 'Start Scheduler' : 'Stop Scheduler'}
            </button>
          </div>

          {/* Add Account Form */}
          <div className="card mb-4">
            <div className="card-header">Add New Account</div>
            <div className="card-body">
              <div className="mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="New Account Email"
                  value={newAccountEmail}
                  onChange={(e) => setNewAccountEmail(e.target.value)}
                />
              </div>
              <div className="mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="New Account Key"
                  value={newAccountKey}
                  onChange={(e) => setNewAccountKey(e.target.value)}
                />
              </div>
              <button className="btn btn-primary w-100" onClick={handleAddAccount}>
                Add Account
              </button>
            </div>
          </div>
        </>
      )}

      {/* Account Data Table */}
      <section>
        <h2 className="mt-4">Account Data</h2>
        <table className="table table-bordered table-striped table-hover">
          <thead className="table-light">
            <tr>
              <th>Email</th>
              <th>ZAR SN</th>
              <th>Balance (ZAR)</th>
              <th>Balance (USDT)</th>
              <th>Total Withdrawn (ZAR)</th>
              <th>Last Checked Time</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {accountData.length === 0 ? (
              <tr>
                <td colSpan="7" className="text-center text-muted">
                  No account data found.
                </td>
              </tr>
            ) : (
              accountData.map((account, index) => (
                <React.Fragment key={index}>
                  <tr>
                    <td>{account.email}</td>
                    <td>{account.zarZN}</td>
                    <td>{account.zarBalance}</td>
                    <td>{account.usdtBalance}</td>
                    <td>{account.totalUSDTWithdrawals}</td>
                    <td>{lastCheckedTimestamps[account.email] || 'N/A'}</td>
                    <td>
                      <div className="d-flex flex-wrap gap-2">
                        {/* Scheduler Control */}
                        {schedulerStatusOneAccount[account.email] !== undefined ? (
                          <button
                            className="btn btn-info btn-sm"
                            onClick={() => toggleSchedulerForOneAccount(account.email)}
                          >
                            {schedulerStatusOneAccount[account.email] === 'stop' ? 'Start' : 'Stop'}
                          </button>
                        ) : (
                          <span className="text-muted">Loading...</span>
                        )}
                        {/* Trade and Withdraw */}
                        <button
                          className="btn btn-success btn-sm"
                          onClick={() => handleTradeWithdrawawButtonClick(account)}
                        >
                          Trade and Withdraw
                        </button>
                        {/* Withdraw Specified Amount */}
                        <input
                          type="number"
                          className="form-control form-control-sm"
                          placeholder="Amount (USDT)"
                          value={withdrawAmounts[account.email] || ''}
                          onChange={(e) =>
                            setWithdrawAmounts({
                              ...withdrawAmounts,
                              [account.email]: e.target.value,
                            })
                          }
                        />
                        <button
                          className="btn btn-success btn-sm"
                          onClick={() =>
                            withdrawSpecifiedUSDTButton(account.email, withdrawAmounts[account.email])
                          }
                          disabled={!withdrawAmounts[account.email]}
                        >
                          Withdraw Specified
                        </button>
                        {/* Withdraw All */}
                        <button
                          className="btn btn-warning btn-sm"
                          onClick={() => withdrawAllUSDTButton(account.email)}
                        >
                          Withdraw All
                        </button>
                        {/* Convert ZAR */}
                        <button
                          className="btn btn-warning btn-sm"
                          onClick={() => convertZar(account.email)}
                        >
                          Convert ZAR
                        </button>
                        {/* Delete Account */}
                        <button
                          className="btn btn-danger btn-sm"
                          onClick={() => handleDeleteAccount(account.email)}
                        >
                          Delete
                        </button>
                        {/* View/Hide Withdrawal History */}
                        <button
                          className="btn btn-info btn-sm"
                          onClick={() => toggleWithdrawalHistory(account.email)}
                        >
                          {visibleHistories[account.email] ? 'Hide Withdrawal History' : 'View Withdrawal History'}
                        </button>
                      </div>
                    </td>
                  </tr>
                  {visibleHistories[account.email] && (
                    <tr>
                      <td colSpan="7">
                        <div className="bg-light p-3">
                          <h5>Withdrawal History for {account.email}</h5>
                          {account.withdrawalHistory?.withdraws?.length ? (
                            <table className="table table-bordered table-sm">
                              <thead>
                                <tr>
                                  <th>Amount (USDT)</th>
                                  <th>Status</th>
                                  <th>Done At</th>
                                </tr>
                              </thead>
                              <tbody>
                                {account.withdrawalHistory.withdraws.map((entry) => (
                                  <tr key={entry.id}>
                                    <td>{entry.amount}</td>                                    
                                    <td>{entry.state}</td>
                                    <td>{new Date(entry.done_at).toLocaleString()}</td>                                    
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          ) : (
                            <p className="text-muted">No withdrawal history available.</p>
                          )}
                        </div>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))
            )}
          </tbody>
        </table>
      </section>
    </div>
  );
};
  
export default AccountSection;
